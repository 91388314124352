.growingBars {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}

.bar {
    width: 15px;
    height: 50px;
    margin: 0 5px;
    background-color: #981B1E;
    animation: grow 1.2s infinite ease-in-out;
}

.bar:nth-child(1) {
    animation-delay: -0.3s;
}
.bar:nth-child(2) {
    animation-delay: -0.6s;
}
.bar:nth-child(3) {
    animation-delay: -0.9s;
}
.bar:nth-child(4) {
    animation-delay: -1.2s;
}

@keyframes grow {
    0%, 100% {
        height: 15px;
    }
    50% {
        height: 50px;
    }
}
